@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: "Poppins", serif;
}

[data-bm-widget-layout=button] {
	border-radius: 160px !important;
	height: 60px !important;
	position: relative !important;
	background: #1a73e8 !important;
	font-weight: 500 !important;
	color: white !important;
	padding: 0 24px 0 42px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loader {
	border-right-color: transparent;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@media (min-width: 768px) {
	[data-bm-widget-layout=button] {
		position: relative !important;
	}
}

@media (min-width: 1280px) {
	[data-bm-widget-layout=button] {
		position: absolute !important;
		right: 20px;
		bottom: 20px;
	}
}